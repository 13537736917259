import React, {Component} from "react";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import avatar1 from "../Assets/Images/avatar-round-1.png"
import avatar2 from "../Assets/Images/avatar-round-2.png"
import avatar3 from "../Assets/Images/avatar-round-3.png"
import avatar4 from "../Assets/Images/avatar-round-4.png"
import avatar5 from "../Assets/Images/avatar-round-5.png"
import avatar6 from "../Assets/Images/avatar-round-6.png"


class Review extends Component {
    state = {
        avatars: [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6]
    };


    render() {
        let review = this.props.review
        const random = Math.floor(Math.random() * this.state.avatars.length);

        return (
            <React.Fragment>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-6">
                                <div className="testimonial testimonial-2">
                                    <div className="testimonial__body boxed boxed--border bg--secondary">
                                        <p className={'text-left'}>{review.review} </p>
                                    </div>
                                    <div className="testimonial__image text-left">
                                        <img alt={"Review of The Pleasant Pheasant by " + review.name} src={this.state.avatars[random]}/>
                                        <h5 className={'mb-1'}>{review.name}</h5>
                                        <span>{review.date}</span>
                                        <ReactStars
                                            count={5}
                                            value={review.stars}
                                            edit={false}
                                            size={24}
                                            activeColor="#ffd700"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Review;
