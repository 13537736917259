import React, {Component} from "react";
import axios from "axios";

class ContactUs extends Component {
    state = {
        success: false,
        error: false,
        contactForm: {
            pot: {
                valid: true,
                value: ''
            },
            name: {
                valid: true,
                value: ''
            },
            email: {
                valid: true,
                value: ''
            },
            message: {
                valid: true,
                value: ''
            }
        },
    };

    constructor(props) {
        super(props);
        this.emptyForm = Object.assign({}, this.state.contactForm);
        this.handleChange = this.handleChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    handleChange(e) {
        e.persist();
        this.setState((prevState) => {
            let contactForm = Object.assign({}, prevState.contactForm);
            if (!contactForm.hasOwnProperty(e.target.name)) {
                contactForm[e.target.name] = {
                    valid: false,
                    value: null
                }
            }
            switch (e.target.name) {
                case "email":
                    contactForm.email.valid = this.validateEmail(e);
                    break;
                default:
                    contactForm[e.target.name].valid = true;

            }
            contactForm[e.target.name].value = e.target.value;
            return {contactForm}
        })
    };

    validateForm() {
        let isValid = true;
        let contactForm = Object.assign({}, this.state.contactForm);
        Object.keys(this.state.contactForm).forEach(field => {
            if (!contactForm[field].valid) {
                isValid = false
            }
        });
        return isValid;
    }

    validateEmail(e) {
        let emailPattern = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
        return emailPattern.test(e.target.value)
    };

    formSubmit = (e) => {
        e.preventDefault();
        // if (!this.recaptchaRef.current.getValue()) {
        //     return this.setState({error: "Please complete the ReCAPTCHA"})
        // }
        this.setState({
            success: false,
            error: false
        });

        let contactForm = Object.assign({}, this.state.contactForm);
        let data = {
            pot: contactForm.pot.value,
            name: contactForm.name.value,
            email: contactForm.email.value,
            message: contactForm.message.value,
        };
        axios.post("/.netlify/functions/contact-form", data)
            .then(response => {
                return this.setState({success: true}, this.resetForm())
            })
            .catch(() => {
                this.setState({error: true})
            });
    };

    resetForm() {
        return function () {
            return this.setState(this.emptyForm)
        };
    }

    render() {
        return (
            <React.Fragment>
                {this.state.success && (<div className="col-12 alert bg--success">
                    <div className="alert__body">
                        <span
                            style={{color: 'black'}}>Thanks, your message has been sent!</span>
                    </div>
                    <div className="alert__close">
                        ×
                    </div>
                </div>)}
                {this.state.error && (<div className="col-12 alert bg--error">
                    <div className="alert__body">
                        <span
                            style={{color: 'black'}}>Sorry, we could not reach our servers. Please try again later.</span>
                    </div>
                    <div className="alert__close">
                        ×
                    </div>
                </div>)}
                <form onSubmit={this.formSubmit}>
                    <div className="col-12 hidden">
                        <label htmlFor='name'>Hello There!:</label>
                        <input type='text' name='pot' id='pot'
                               onChange={this.handleChange}
                               value={this.state.contactForm.pot.value}/>
                    </div>
                    <div className="col-12">
                        <label htmlFor='name'>Name:</label>
                        <input type='text' name='name' id='name' required
                               onChange={this.handleChange}
                               value={this.state.contactForm.name.value}/>
                    </div>
                    <div className="col-12">
                        <label htmlFor='name'>Email Address:</label>
                        <input type='email' name='email' id='email' required
                               onChange={this.handleChange}
                               value={this.state.contactForm.email.value}/>
                    </div>
                    <div className="col-md-12 col-12">
                        <label htmlFor='message'>Message:</label>
                        <textarea type='text' name='message' id='message' rows='4' required
                                  onChange={this.handleChange}
                                  value={this.state.contactForm.message.value}
                        />
                    </div>
                    <div className={'col-md-5 col-lg-4 col-6'}>
                        <button type='submit' className={'btn btn--primary type--uppercase'}>Submit</button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default ContactUs;
