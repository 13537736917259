import React, {Component} from "react";
import background from "../Assets/Images/thepleasantpheasant-background.jpg"
import background2 from "../Assets/Images/montana-vacation-rentals-home-bg-800x300.jpg"
import background3 from "../Assets/Images/destination1-500x383@2x.jpg"
import mainLogHome from "../Assets/Images/cabins-for-rent-near-yellowstone-overlay-header-669x272.jpg"
import theOutHouse from "../Assets/Images/the-out-house-cabin.jpg"
import bunkHouse from "../Assets/Images/bunkhouse-above-garage.jpg"
import {Link} from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';


class Index extends Component {
    state = {};

    async componentDidMount() {
        window.mr.documentReady();
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover height-80 imagebg text-center parallax" data-overlay="3">
                        <div className="background-image-holder">
                            <img alt="background" src={background}/>
                        </div>
                        <div className="container pos-vertical-center">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="title">Pleasant Pheasant<br/>Montana Vacation Rentals</h1>
                                    <h2>A vacation rental retreat in Pray, Montana - outside of Yellowstone National
                                        Park</h2>
                                    <Link className="btn btn--primary type--uppercase" to={'/our-homes'}>
                                        <span className="btn__text">View All Properties</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="text-center imagebg" data-overlay="4">
                        <div className="background-image-holder">
                            <img alt="background" src={background2}/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-lg-8">
                                    <p className="lead"> In the heart of scenic Paradise Valley, Montana, this Vacation
                                        Home Rental and cabins are situated on 20 private acres just 35 miles from the
                                        northern entrance to Yellowstone National Park. This is the perfect rental
                                        destination for large families or reunions, with up to 9 bedrooms/5 baths and
                                        sleeps up to 19 guests. </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <h2 className="text-center">OUR PROPERTIES</h2>
                            <h4 className="text-center">In the peak summer months, we rent all three houses together.
                                Maximum occupancy is 19 persons. Houses can be rented separately during the
                                off-season.</h4>
                            <div className="masonry">
                                <div className="masonry__container row masonry--active">
                                    <div className="masonry__item col-12">
                                        <div className="project-thumb hover-element border--round hover--active">
                                            <NavHashLink to={'/our-homes#mainLogHome'}>
                                                <div className="hover-element__initial">
                                                    <div className="background-image-holder">
                                                        <img alt="background" src={mainLogHome}/>
                                                    </div>
                                                </div>
                                                <div className="hover-element__reveal" data-scrim-top="2">
                                                    <div className="project-thumb__title">
                                                        <h4>Main Log Home</h4> <span>Adventure, Getaways</span></div>
                                                </div>
                                            </NavHashLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="masonry__container row masonry--active">
                                    <div className="masonry__item col-6">
                                        <div className="project-thumb hover-element border--round hover--active">
                                            <NavHashLink to={'/our-homes#bunkhouse'}>
                                                <div className="hover-element__initial">
                                                    <div className="background-image-holder"><img alt="background"
                                                                                                  src={bunkHouse}/>
                                                    </div>
                                                </div>
                                                <div className="hover-element__reveal" data-scrim-top="5">
                                                    <div className="project-thumb__title">
                                                        <h4>Bunkhouse</h4> <h5>Above Garage</h5><span>Adventure</span>
                                                    </div>
                                                </div>
                                            </NavHashLink>
                                        </div>
                                    </div>
                                    <div className="masonry__item col-6">
                                        <div className="project-thumb hover-element border--round hover--active">
                                            <NavHashLink to={'/our-homes#cabins'}>
                                                <div className="hover-element__initial">
                                                    <div className="background-image-holder"><img alt="background"
                                                                                                  src={theOutHouse}/>
                                                    </div>
                                                </div>
                                                <div className="hover-element__reveal" data-scrim-top="5">
                                                    <div className="project-thumb__title">
                                                        <h4>The Outhouse/Cabin</h4> <span>Adventure</span></div>
                                                </div>
                                            </NavHashLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="text-center imagebg" data-overlay="4">
                        <div className="background-image-holder"><img alt="background"
                                                                      src={background3}/></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-10">
                                    <div className="slider" data-paging="true">
                                        <ul className="slides">
                                            <li>
                                                <div className="testimonial">
                                                    <blockquote> “What a great vacation and property! The house/property
                                                        is well equipped and comfortable. We had 3 families with
                                                        children ranging in ages 4-12 along with a set of grandparents.
                                                        We had room to spread out and relax and plenty of outdoor space
                                                        for kickball and other outdoor games while enjoying the amazing
                                                        scenery. The house is centrally located to Yellowstone, big sky,
                                                        and many other amazing sites. The vacation and property was
                                                        fabulous!”
                                                    </blockquote>
                                                    <h5>Anne</h5> <span>Date of Stay:  June 24, 2017</span></div>
                                            </li>
                                            <li>
                                                <div className="testimonial">
                                                    <blockquote> “The Pleasant Pheasant exceeded my family needs. The
                                                        airport was an hour away and the entrance to Yellowstone was
                                                        about 40 minutes away. Between Bozemen and the park were
                                                        fantastic eateries and grocery stores. The house is equipped
                                                        will all major and small appliances. Plenty of tableware and
                                                        cutlery to cover large groups. The best part of the house is the
                                                        panoramic view. The view of the mountains is breathtaking and
                                                        the quiet made everyone feel relax after an adventurous day.
                                                        Having the enclosed patio allow us to enjoy watching the sunset
                                                        and seeing the sky and mountains change color. Thank you again,
                                                        Margie. I’m sure your future guests will equally enjoy their
                                                        stay!”
                                                    </blockquote>
                                                    <h5>Vanessa</h5> <span>Date of Stay: July 8, 2017</span></div>
                                            </li>
                                            <li>
                                                <div className="testimonial">
                                                    <blockquote> “My large family gathers annually to share our
                                                        Thanksgiving and the Pleasant Pheasant was the perfect location.
                                                        Because we need lots of bedrooms and a large gathering space,
                                                        this home worked very well for us to share large meals, visit,
                                                        and still have some private space as well. Great views, nice
                                                        walks, close to Chico for soaking in the hot springs, and the
                                                        guys especially enjoyed scoping the elk herd on the side of the
                                                        mountain–terrific Thanksgiving weekend.”
                                                    </blockquote>
                                                    <h5>carltone2017</h5> <span>Date of Stay: Nov 2016</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
