import React, {Component} from "react";
import background from "../Assets/Images/cabins-for-rent-near-yellowstone-overlay-header.jpg"
import mainLogHome from "../Assets/Images/cabins-for-rent-near-Yellowstone-main-lodge-gallery1.jpg"
import bunkhouse from "../Assets/Images/cabins-for-rent-near-Yellowstone-bunkhouse-gallery1.jpg"
import cabins from "../Assets/Images/cabins-for-rent-near-Yellowstone-cabins-gallery1.jpg"

class OurHomes extends Component {
    state = {};
    async componentDidMount() {
        window.mr.documentReady();
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover height-50 imagebg text-center" data-overlay="3">
                        <div className="background-image-holder">
                            <img alt="background" src={background}/>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1>Learn More About Our Cabins for Rent near Yellowstone</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="switchable">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12">
                                    <p className="lead"> The Pleasant Pheasant is located on 20 private acres in
                                        Paradise Valley, Montana. The property consists of a 5 bedroom, 3 story, log
                                        home, a 3 bedroom bunkhouse, and a one-bedroom cabin. This is the perfect
                                        setting for family reunions and large groups to stay together, but have their
                                        own privacy, all right in the backyard of Yellowstone National Park! </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={'mainLogHome'}>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <div className="boxed boxed--lg boxed--border bg--secondary">
                                        <img alt="cabins for rent near Yellowstone - Main Log Home" src={mainLogHome} className="border--round"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4><strong>Main Log Home:</strong></h4>
                                    <p className="lead"><strong>Upper floor</strong> – 1 king bedroom, 1 queen bedroom,
                                        each with balcony and chairs, 1 full shared bathroom<br/><strong>Main
                                            floor</strong> – 1 queen bedroom, 1 full bath (along with a full kitchen,
                                            living room, dining room, laundry, screened porch, etc.)<br/><strong>Lower
                                                level</strong> – 1 queen bedroom, 1 bedroom with 2 single beds, 1 full
                                                shared bathroom, 1 full kitchen, living room with satellite TV.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={'bunkhouse'}>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <h4><strong>Bunkhouse:</strong></h4>
                                    <p className="lead"> 3 bedrooms, each with a queen-sized bed, fold-out queen size
                                        sofa bed, full kitchen (with exception of a dishwasher), bathroom, dining, TV
                                        room with satellite, washer/dryer, and balcony. </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="boxed boxed--lg boxed--border bg--secondary">
                                        <img alt="cabins for rent near Yellowstone - Bunkhouse" src={bunkhouse} className="border--round"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={'cabins'}>
                        <div className="container">
                            <div className="row justify-content-between">

                                <div className="col-md-6">
                                    <div className="boxed boxed--lg boxed--border bg--secondary">
                                        <img alt="cabins for rent near Yellowstone - cabins" src={cabins} className="border--round"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4><strong>Cabin:</strong></h4>
                                    <p className="lead"> 1 bedroom (Full/Double size bed), bathroom, mini-sized
                                        kitchen-2 stove burners, mini-fridge, microwave, coffee maker, outdoor
                                        seating.<br/><br/>At the end of your day exploring all that the Livingston area
                                        has to offer you can relax on one of the porches or balconies that surround
                                        the houses while you watch the setting sun light up the mountains. For
                                        larger groups, we offer several fold-out tables/chairs for outside
                                        seating.<br/></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </React.Fragment>
        );
    }
}

export default OurHomes;
