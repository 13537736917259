import React, {Component} from "react";
import logo from "../Assets/Images/thepleasantpheasant-sign.png";
import './navigation.css'
import {Link} from "react-router-dom";
class Navigation extends Component {
    state = {
        pages: [
            {
                name: "Home",
                link: "/"
            },
            {
                name: "About Us",
                link: "/about-us"
            },
            {
                name: "Gallery",
                link: "/gallery"
            },
            {
                name: "Accommodations",
                link: "/our-homes"
            },
            {
                name: "Reviews",
                link: "/reviews"
            },
            {
                name: "Reservations",
                link: "/make-a-reservation"
            }
        ]
    };

    render() {
        return (
            <React.Fragment>
                <div className="nav-container">
                    <div className="via-1622769468764" via="via-1622769468764" vio="pp">
                        <div className="bar bar--sm visible-xs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-3 col-md-2">
                                        <Link to="/">
                                            <img className="logo logo-dark" alt="Pleasant Pheasant Logo" src={logo}/>
                                            <img className="logo logo-light" alt="Pleasant Pheasant Logo" src={logo}/>
                                        </Link>
                                    </div>
                                    <div className="col-9 col-md-10 text-right">
                                        <a href="#menu2" className="hamburger-toggle"
                                           data-toggle-class="#menu2;hidden-xs hidden-sm">
                                            <i className="icon icon--sm stack-interface stack-menu"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav id="menu2" className="bar bar-2 hidden-xs bar--absolute">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 text-right hidden-sm">
                                        <div className="bar__module">
                                            <ul className="menu-horizontal mt-4">
                                                {this.state.pages.slice(0, Math.ceil(this.state.pages.length / 2))
                                                    .map((page, index) => {
                                                        return (
                                                            <li className={'ml-5 mr-5'} key={index}>
                                                                <Link to={page.link} className={'text-uppercase'}>{page.name}</Link>
                                                            </li>
                                                        )
                                                    })}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 text-center text-left-sm hidden-xs">
                                        <div className="bar__module">
                                            <Link to="/">
                                                <img className="logo logo-dark" alt="Pleasant Pheasant Logo"
                                                     src={logo}/>
                                                <img className="logo logo-light" alt="Pleasant Pheasant Logo"
                                                     src={logo}/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 text-left hidden-sm">
                                        <div className="bar__module">
                                            <ul className="menu-horizontal mt-4">
                                                {this.state.pages.slice(-(Math.ceil(this.state.pages.length / 2)))
                                                    .map((page, index) => {
                                                        return (
                                                            <li className={'ml-4 mr-4'} key={index}>
                                                                <Link to={page.link} className={'text-uppercase'}>{page.name}</Link>
                                                            </li>
                                                        )
                                                    })}

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 text-center visible-sm">
                                        <div className="bar__module">
                                            <ul className="menu-horizontal mt-4">
                                                {this.state.pages.map((page, index) => {
                                                        return (
                                                            <li className={'ml-4 mr-4'} key={index}>
                                                                <Link to={page.link} className={'text-uppercase'}>{page.name}</Link>
                                                            </li>
                                                        )
                                                    })}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Navigation;
