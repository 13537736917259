import React, {Component} from "react";
import background from "../Assets/Images/montana-vacation-rentals-home-bg-800x300.jpg"
import hero1 from "../Assets/Images/hero-1.jpg"
import ContactUs from "../Components/contactUs";


class MakeAReservation extends Component {
    state = {};

    async componentDidMount() {
        window.mr.documentReady();
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover height-50 imagebg text-center" data-overlay="3">
                        <div className="background-image-holder"><img alt="background" src={background}/></div>
                    </section>
                    <section className="text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1>Reserve our Montana Vacation Rentals</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="switchable">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12">
                                    <p className="lead"> Interested in booking our entire property or one of our lovely
                                        cabins near Yellowstone National Park?<br/><br/>Reservations can be made by
                                        phone,
                                        e-mail, or through our booking site (link below.) Rates shown are for
                                        renting all available houses as a group. Please call us for individual cabin
                                        rates and discounts for smaller groups (off-season only.) If you have any
                                        questions whatsoever, please do not hesitate to call. We look forward to
                                        helping you plan your Montana vacation!</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="switchable imagebg" data-overlay="4">
                        <div className="background-image-holder"><img alt="background" src={hero1}/></div>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-5">
                                    <iframe
                                        title={"Map to Pleasant Pheasant Montana Vacation Rentals"}
                                        className="map-container border--round"
                                        width="450"
                                        height="250"
                                        frameBorder="0" style={{border: "0"}}
                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAnxRmFD54IblE6hyJKF0GAjyBXGhA2Uxs&q=The+Pleasant+Pheasant+126+Elbow+Creek+Road+Livingston%2C+MT+59047+United+States">
                                    </iframe>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="text-block">
                                        <p className="lead">P: (404) 245-0450 </p>
                                        <p className="lead"> Have more questions? Feel free to reach out and talk to us
                                            about any of our properties or a reservation!</p>
                                    </div>
                                    <ContactUs/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default MakeAReservation;
