import React, {Component} from "react";
import {Link} from "react-router-dom";

class Footer extends Component {
    state = {
        pages: [
            {
                name: "Home",
                link: "/"
            },
            {
                name: "About Us",
                link: "/about-us"
            },
            {
                name: "Gallery",
                link: "/gallery"
            },
            {
                name: "Accommodations",
                link: "/our-homes"
            },
            {
                name: "Reviews",
                link: "/reviews"
            },
            {
                name: "Reservations",
                link: "/make-a-reservation"
            },
            {
                name: "Admin Panel",
                link: "/admin/#/"
            }
        ]
    };


    render() {
        let year = new Date().getFullYear()
        return (
            <React.Fragment>
                <footer className="text-center space--sm footer-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heading-block">
                                    <ul className="list-inline list--hover">
                                        {this.state.pages.map((page, index) => {
                                            if (page.name === "Admin Panel") {
                                                return (
                                                    <li key={index}><a href={page.link}><span>{page.name}</span></a>
                                                    </li>
                                                )
                                            }
                                            return (
                                                <li key={index}><Link to={page.link}><span>{page.name}</span></Link>
                                                </li>

                                            )
                                        })}
                                    </ul>
                                </div>
                                <div>
                                    <ul className="social-list list-inline list--hover">
                                        <li>
                                            <a href="https://goo.gl/maps/Mi5mcWWjj7btxQ1i6">
                                                <i className="socicon socicon-google icon icon--xs"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/TPPvacationrental/">
                                                <i className="socicon socicon-facebook icon icon--xs"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/tppvacationrental/">
                                                <i className="socicon socicon-instagram icon icon--xs"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div><span className="type--fine-print">Designed by Chat Sumlin</span></div>
                                <div><span className="type--fine-print">© <span className="update-year">{year}</span> The Pleasant Pheasant </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
