import React, {Component} from "react";
import data from "../Assets/reviews.json"
import Review from "../Components/review";
import background from "../Assets/Images/cabins-for-rent-near-yellowstone-overlay-header.jpg";

class Reviews extends Component {
    state = {
        data: data
    };
    async componentDidMount() {
        window.mr.documentReady();
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover imagebg text-center height-30" data-overlay="3">
                        <div className="background-image-holder">
                            <img alt="The Pleasant Pheasant near Yellowstone Main Cabin" src={background}/>
                        </div>
                    </section>
                    {this.state.data.map((review) => {
                        return <Review review={review}/>
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default Reviews;
