import React, {Component} from "react";
import background from "../Assets/Images/DiningLG.jpg";
import gallery from '../gallery.json'

class Gallery extends Component {
    state = {};

    async componentDidMount() {
        window.mr.documentReady();
        window.mr.masonry.windowLoad()
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover imagebg text-center height-30" data-overlay="3">
                        <div className="background-image-holder"><img alt="background" src={background}/></div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="masonry">
                                <div className="masonry__container row">
                                    {gallery ? gallery.map((img) => {
                                        return (
                                            <div className="masonry__item col-lg-4 col-6" key={img.id}>
                                                <a href={img.image} data-lightbox="Gallery 1">
                                                    <img alt={img.alt} src={img.image} title={img.title}/>
                                                </a>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default Gallery;
