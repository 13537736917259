import React, {Component} from "react";
import background from "../Assets/Images/DiningLG.jpg"
import frontDoor from "../Assets/Images/FrontDoorViewLG-1024x768-640x480.jpg"
import doubleRainbow from "../Assets/Images/the-pleasant-pheasant-double-rainbow.jpg"

class About extends Component {
    state = {};

    async componentDidMount() {
        window.mr.documentReady();
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-container">
                    <section className="cover imagebg text-center height-60" data-overlay="3">
                        <div className="background-image-holder"><img alt="background" src={background}/></div>
                        <div className="container pos-vertical-center">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1> Our mission is to offer travelers an unforgettable vacation, with extra
                                        amenities, at affordable rates without added fees. </h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <h2>The Pleasant Pheasant: Yellowstone Vacation Rentals</h2>
                                    <p className="lead"> Located on 20 private acres with amazing views and wildlife,
                                        the Pleasant Pheasant is the perfect spot for single or multiple families or
                                        groups to vacation together but still have privacy. Originally built as a family
                                        retreat and operating as a Bed &amp; Breakfast for 10 years, the Pleasant
                                        Pheasant is now available for rent. Wonderful for families, sportsmen, reunions,
                                        or those simply wanting to enjoy the great outdoors. </p>
                                </div>
                                <div className="col-md-6">
                                    <div><img alt="View from the front door of the main cabin"
                                              src={frontDoor} className="border--round"/>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <p className="lead"> Pleasant Pheasant properties include a 5 bedroom log home with
                                        cathedral ceiling, a large outdoor deck, and screened-in porch. The three-story
                                        home has 5 bedrooms, 3 bathrooms, two full kitchens and two living rooms,
                                        sleeping up to 11 guests. </p>
                                </div>
                            </div>
                            <div className="row justify-content-between mt-5">
                                <div className="col-md-6">
                                    <div><img alt="Double rainbow Yellowstone National Park"
                                              src={doubleRainbow}
                                              className="border--round"/></div>
                                </div>
                                <div className="col-md-6">
                                    <p className="lead"> Optional additions include a 3 bedroom, 1 bath Bunkhouse with
                                        kitchen &amp; laundry, and a 1 bedroom, 1 bath cabin, sleeping up to 8
                                        additional guests. </p>
                                    <p className="lead"> Further Details: In the heart of scenic Paradise Valley, we are
                                        17 miles south of Livingston and 35 miles north of Yellowstone National Park.
                                        One hour from Bozeman Airport. Chico Hot Springs is 10 minutes away. Nearby
                                        activities include river rafting on the Yellowstone, fishing, hiking, horseback
                                        riding, clay shooting, hunting, dog-sledding, and mountain bike riding. A fly
                                        fisherman’s paradise, the Valley also boasts three of the world’s top spring
                                        creeks, all within a ten-minute drive. </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="switchable switchable--switch">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-6 col-md-7 col-12">
                                    <iframe
                                        title={"Map to Pleasant Pheasant Montana Vacation Rentals"}
                                        className="map-container border--round"
                                        width="450"
                                        height="250"
                                        frameBorder="0" style={{border:'0'}}
                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAnxRmFD54IblE6hyJKF0GAjyBXGhA2Uxs&q=The+Pleasant+Pheasant+126+Elbow+Creek+Road+Livingston%2C+MT+59047+United+States">
                                    </iframe>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="switchable__text">
                                        <h3>126 Elbow Creek Road <br/>Livingston, MT 59047 <br/>United States</h3>
                                        <p className="lead"> E: <a href="mailto:pleasantpheasant@comcast.net">pleasantpheasant@comcast.net</a><br/> P: (404) 245-0450
                                        </p>
                                        <p className="lead"> Give us a call or drop by anytime, we endeavour to answer
                                            all enquiries within 24 hours on business days. </p>
                                        <p className="lead"> We are open all the time! </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default About;
