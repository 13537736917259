import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import About from "./Pages/about";
import Index from "./Pages";
import Footer from "./Components/footer";
import Navigation from "./Components/navigation";
import MakeAReservation from "./Pages/make-a-reservation";
import OurHomes from "./Pages/our-homes";
import Reviews from "./Pages/reviews";
import Gallery from "./Pages/gallery";

function App() {
    return (
        <Router>
            <div className="App">
                <Navigation/>
                <Switch>
                    <Route path="/about-us" exact>
                        <About/>
                    </Route>
                    <Route path="/" exact>
                        <Index/>
                    </Route>
                    <Route path="/make-a-reservation" exact>
                        <MakeAReservation/>
                    </Route>
                    <Route path="/our-homes" exact>
                        <OurHomes/>
                    </Route>
                    <Route path="/reviews" exact>
                        <Reviews/>
                    </Route>
                    <Route path="/gallery" exact>
                        <Gallery/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
